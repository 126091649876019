<template>
    <header class="py-3 bg-white small-shadow" title="Alessandra Guerriero Massaggiatrice Olistica">
        <div class="container d-flex justify-content-between">
            <a href="#" class="logo h-100 mw-100">
                <img src="../../public/img/Alessandra Guerriero Operatrice Olistica.svg"
                    alt="Alessandra Guerriero Operatrice Olistica">
            </a>
            <div class="d-flex flex-column justify-content-center">
                <ul class="d-flex gap-5 list-unstyled mb-0 align-items-center gallery text-green px-5 fs-5" id="navbar">
                    <li><a :href="firstLink" :title="firstTitle">{{ firstTitle }}</a></li>
                    <li><a :href="secondLink" :title="secondTitle">{{ secondTitle }}</a></li>
                    <li><a href="javascript:void" @click="showHideContacts()" :title="fourthTitle">{{ fourthTitle }}</a>
                    </li>
                </ul>
                <div id="menu">
                    <div class="toggle-btn" @click="toggleMenu()">
                        <div id="burger-menu">
                            <div class="rounded bg-green w-100" id="first"></div>
                            <div class="rounded bg-green w-100" id="second"></div>
                            <div class="rounded bg-green w-100" id="third"></div>
                        </div>
                    </div>
                    <div class="opacity-0 pe-none small-shadow gallery text-green fs-5 bg-white py-3" id="toggle-menu">
                        <ul class="list-unstyled">
                            <li class="p-2"><a :href="firstLink">{{ firstTitle }}</a></li>
                            <li class="p-2"><a :href="secondLink">{{ secondTitle }}</a></li>
                            <li class="p-2"><a :href="fourthLink" @click="showHideContacts()" id="contacts-buttn">{{
                                fourthTitle }}</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div id="contacts-modal" class="d-none">
            <div id="contacts" class="bg-white text-green text-center p-3 position-relative">
                <i class="fa-solid fa-xmark fs-3 pointer text-green position-absolute top-0 end-0 pt-3 pe-4"
                    @click="hideContacts()"></i>
                <h3>Contatti:</h3>
                <ul class="list-group list-group-flush fs-5 pt-3 contacts-list">
                    <li class="list-group-item border-champagne text-green"><a target="_blank" href="tel:+393488125402"><i
                                class="fa-solid fa-phone pe-3"></i>3488125402</a></li>
                    <li class="list-group-item border-champagne text-green"><a target="_blank" href="https://goo.gl/maps/b4jJ9vwJJ6UK94ZXA"><i
                                class="fa-solid fa-location-dot pe-3"></i>Via Bizzarri, 171, Manduria (TA)</a></li>
                    <li class="list-group-item border-champagne text-green"><a target="_blank"
                            href="https://www.linkedin.com/in/alessandra-guerriero-operatrice-olistica/"><i
                                class="fa-brands fa-linkedin pe-3"></i>Alessandra Guerriero</a></li>
                    <li class="list-group-item border-champagne text-green"><a target="_blank"
                            href="https://www.instagram.com/_alessandraguerriero_/"><i
                                class="fa-brands fa-instagram pe-3"></i>@_alessandraguerriero_</a></li>
                    <li class="list-group-item border-champagne text-green"><a target="_blank"
                            href="https://www.facebook.com/alessandra.guerriero.operatrice.olistica/"><i
                                class="fa-brands fa-facebook pe-3"></i>Alessandra Guerriero</a></li>
                </ul>
            </div>
        </div>
    </header>
</template>

<script>
export default {
    data() {
        return {
            firstLink: "#chi-sono",
            firstTitle: "Chi sono",
            secondLink: "#servizi",
            secondTitle: "Servizi",
            fourthTitle: "Contatti",
            fourthLink: "javascript:void(0);"
        }
    },
    methods: {
        showHideContacts() {
            const contactsModal = document.getElementById("contacts-modal");
            const contacts = document.getElementById("contacts");
            if (contactsModal.classList.contains("d-none")) {
                contactsModal.classList.remove("d-none");
                contacts.classList.remove("closing");
            } else {
                contacts.classList.add("closing");
                setTimeout(function () {
                    contactsModal.classList.add("d-none");
                }, 200)
            }
        },
        hideContacts() {
            const contactsModal = document.getElementById("contacts-modal");
            const contacts = document.getElementById("contacts");
            contacts.classList.add("closing");
            setTimeout(function () {
                contactsModal.classList.add("d-none");
            }, 200)
        },
        toggleMenu() {
            const toggle = document.getElementById("burger-menu");
            const menu = document.getElementById("toggle-menu");
            if (toggle.classList.contains('after')) {
                toggle.classList.remove("after");
                menu.classList.replace("opacity-100", "opacity-0");
                menu.classList.replace("pe-auto", "pe-none");
            } else {
                toggle.classList.add("after");
                menu.classList.replace("opacity-0", "opacity-100");
                menu.classList.replace("pe-none", "pe-auto");
            }
        }
    }
}
</script>

<style scoped lang="scss">
header {
    position: sticky;
    top: 0;
    z-index: 10;
    filter: drop-shadow(0px 25px 50px #F4E0C8);

    .logo {
        img {
            height: 40px;
        }
    }

    #contacts-modal {
        position: absolute;
        top: 100%;
        right: 0;
        left: 0;
        height: calc(100vh - 72px);
        background: rgba(var(--dark-green-rgb), 0.25);
        padding: 1rem 0;
        z-index: 11;

        #contacts {
            border-radius: 1rem;
            width: 600px;
            max-width: calc(100% - 2rem);
            margin: auto;
            animation: 300ms ease appear;

            .contacts-list li {
                transition: 300ms;

                &:hover {
                    text-shadow: 0 5px 2px var(--champagne);
                }
            }
        }

        #contacts.closing {
            animation: 300ms ease disappear;
        }
    }

    #navbar,
    #toggle-menu>ul {
        li {
            transition: 300ms;

            &:hover {
                filter: drop-shadow(0 5px 2px var(--champagne));
                color: var(--champagne);
            }
        }
    }

    #menu {
        display: none;

        #toggle-menu {
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            text-align: center;
            transition: 200ms ease;
        }

        .toggle-btn {
            width: 42px;
            height: 42px;
            padding: 0.5rem;
            color: var(--primary-green);
            cursor: pointer;

            #burger-menu {
                position: relative;
                height: 100%;

                #first,
                #second,
                #third {
                    height: 2px;
                    position: absolute;
                    transition: 300ms ease;
                }

                #first {
                    top: 10%;
                }

                #second {
                    top: 50%;
                    transform: translateY(-50%);
                    opacity: 1;
                }

                #third {
                    top: 80%;
                }
            }

            #burger-menu.after {
                #first {
                    top: 52%;
                    transform: rotate(45deg) translateY(-50%);
                }

                #second {
                    opacity: 0;
                }

                #third {
                    top: 48%;
                    transform: rotate(-45deg) translateY(50%);
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    header {
        #navbar {
            display: none !important;
        }

        #menu {
            display: block;
        }
    }
}

@keyframes appear {
    from {
        opacity: 0;
        transform: scale(1%);
    }

    to {
        opacity: 1;
        transform: scale(100%);
    }
}

@keyframes disappear {
    from {
        opacity: 1;
        transform: scale(100%);
    }

    to {
        opacity: 0;
        transform: scale(1%);
    }
}</style>