<template>
    <div class="py-5 bg-champagne" id="servizi">
        <div class="container py-5">
            <h2 class="text-center">I miei servizi</h2>
            <h3 class="text-center">Un approccio olistico per il tuo benessere</h3>
            <div class="row py-4">
                <div class="col-12 col-md-6 col-lg-4 p-3" v-for="service in servicesCards" :key="service.title"
                    :id="service.id" @click="showContent(service.id)">
                    <RoundedCard :img-url="service.url" :title="service.title" :description="service.description" />
                </div>
            </div>
        </div>
        <div class="my-modal py-4 d-none" v-for="service in services" :key="service.id" :id="'modal' + service.id">
            <div class="container">
                <div class="content bg-white px-4 pb-4 d-flex flex-column" :id="'content' + service.id">
                    <div class="d-flex justify-content-between bg-white pt-4 border-bottom mb-3">
                        <h3>{{ service.title }}</h3><i class="fa-solid fa-xmark fs-3 pointer text-green"
                            @click="hideContent(service.id)"></i>
                    </div>
                    <div class="overflow-auto" v-html="service.content"></div>
                    <div class="py-1 border-top"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import RoundedCard from '../utilities/RoundedCard.vue';

export default {
    components: { RoundedCard },
    data() {
        return {
            servicesCards: [
                {
                    id: 1,
                    title: "Massaggio",
                    description: "Scegliere di ricevere il massaggio vuol dire amare il tuo corpo e ciò che esso custodisce. Lascerai stanchezza, tensione muscolare e stress mentale ritrovando buonumore e benessere generale.",
                    url: "./img/thumbs/Massaggio.jpg"
                },
                {
                    id: 2,
                    title: "Floriterapia",
                    description: "Intraprendere il viaggio con i fiori di Bach vuol dire voler ri-connettere te stesso/a con la tua vera essenza. Scopriremo insieme il rimedio floreale più adatto a te attraverso pratiche olistiche e sensoriali.",
                    url: "./img/thumbs/Floriterapia.jpg"
                },
                {
                    id: 3,
                    title: "Rituali Sensoriali",
                    description: "Coinvolgono anima, mente e corpo. Attraverso dolci pratiche olistiche ti accompagnerò in un viaggio sensoriale unico per ritrovare l'equilibrio e l'armonia generale.",
                    url: "./img/thumbs/Rituali%20Sensoriali.jpg"
                },
                {
                    id: 4,
                    title: "Corsi di formazione",
                    description: "Sei una persona che vuole intraprendere un nuovo percorso o sei un professionista che vuole ampliare le proprie conoscenze? I corsi di formazione ti permettono di sperimentare nuovi modi di fare benessere.",
                    url: "./img/thumbs/Corsi%20di%20Formazione.jpg"
                },
                {
                    id: 5,
                    title: "Collaborazioni",
                    description: "Amo collaborare con altre figure che possano contribuire al benessere di tutti. Tra eventi e giornate di benessere, i modi di collaborare sono tanti. Scopri quali o proponimi la tua idea.",
                    url: "./img/thumbs/Collaborazioni.jpg"
                },
                {
                    id: 6,
                    title: "Crescita personale",
                    description: "Attraverso le pratiche olistiche ti accompagnerò nel tuo viaggio di crescita personale. Potrai riscoprire te stesso, connetterti con i tuoi desideri e trovare la tua autenticità.",
                    url: "./img/thumbs/Crescita%20Personale.jpg"
                },

            ],
            services: [
                {
                    id: 1,
                    title: "Massaggio",
                    content: `<p>Per me è importante prendere in considerazione ogni aspetto della persona.<br>
                                Con i miei <strong>massaggi olistici</strong> ti prenderai cura di anima, mente e corpo.<br>

                                Ogni massaggio ha la sua specifica funzione ma si adatta sempre alle tue esigenze del momento.</p>
                                <h4>Viso</h4>
    <div class="accordion" id="accordionExample">
        <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    <strong>Face Therapy</strong>
                </button>
            </h2>
            <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    <p>Trattamento viso con pochi prodotti naturali per la detersione, in combinazione a manovre di
                        massaggio distensive, rilassanti ed avvolgenti.<br>L'obiettivo è quello di regalarti un momento
                        di grande rilassamento psicofisico.</p>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="headingTwo">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                <strong>Biolifting Viso</strong>
                </button>
            </h2>
            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    <p>Trattamento viso avanzato che sfrutta pochi prodotti naturali per la detersione, in combinazione a
                        manovre di massaggio decontratturanti, distensive, riattivanti della circolazione sanguigna e
                        linfatica, rimodellanti e liftanti.<br>
                        L'obiettivo è quello di donare al tuo viso un aspetto più giovane, luminoso e liftato oltre che
                        regalarti un momento di reale benessere psicofisico.</p>
                </div>
            </div>
        </div>
    </div>
    <br>
    <h4>Corpo</h4>
    <div class="accordion" id="accordionTwo">
        <div class="accordion-item">
            <h2 class="accordion-header" id="heading-3">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapse-3" aria-expanded="true" aria-controls="collapse-3">
                <strong>Linfodrenaggio Psico-Viscerale (parziale o completo)</strong>
                </button>
            </h2>
            <div id="collapse-3" class="accordion-collapse collapse" aria-labelledby="heading-3"
                data-bs-parent="#accordionTwo">
                <div class="accordion-body">
                    <p>Massaggio che si avvale di pompaggi lungo tutto il corpo, molto avvolgente ed estremamente
                        drenante rivolto al potenziamento della linfa. <br>Aiuta ad eliminare i liquidi di
                        ristagno e ad apportare nuova salute al tuo corpo, dona leggerezza alle tue gambe e alla tua
                        mente. <br>In combinazione alla respirazione viscerale (organi interni) ritroverai anche un
                        beneficio emozionale e psicosomatico.</p>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="heading-4">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapse-4" aria-expanded="false" aria-controls="collapse-4">
                <strong>Linfodrenaggio in Gravidanza</strong>
                </button>
            </h2>
            <div id="collapse-4" class="accordion-collapse collapse" aria-labelledby="heading-4"
                data-bs-parent="#accordionTwo">
                <div class="accordion-body">
                    <p>Adatto alle gestanti: un momento magico per la mamma e
                        il suo bambino, per vivere un massaggio in armonia, leggerezza e ascolto. <br>
                        Aiuta ad eliminare i liquidi di
                        ristagno e ad apportare nuova salute al tuo corpo, dona leggerezza alle tue gambe e alla tua
                        mente.</p>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="heading-5">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapse-5" aria-expanded="false" aria-controls="collapse-5">
                <strong>Decontratturante / Riequilibrio Corporeo (schiena o corpo)</strong>
                </button>
            </h2>
            <div id="collapse-5" class="accordion-collapse collapse" aria-labelledby="heading-5"
                data-bs-parent="#accordionTwo">
                <div class="accordion-body">
                    <p>Insieme di più tecniche di massaggio posturali e riequilibranti che agiscono sulle contratture
                        muscolari permettendo il rilascio fasciale (connettivale, muscolo fasciale, articolare, trigger
                        point, ecc...). <br>Ogni tensione fisica e mentale lascerà spazio alla distensione, all'allungamento e
                        alla leggerezza sia della zona trattata che delle zone di riflesso. <br>Ti sentirai più rilassato,
                        in salute e la tua postura ne gioverà. </p>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="heading-6">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapse-6" aria-expanded="false" aria-controls="collapse-6">
                <strong>Riequilibrio Muscolo-Linfatico (parziale o completo)</strong>
                </button>
            </h2>
            <div id="collapse-6" class="accordion-collapse collapse" aria-labelledby="heading-6"
                data-bs-parent="#accordionTwo">
                <div class="accordion-body">
                    <p>Ha un effetto riequilibrante dell'energia muscolare e un effetto di rimodellamento delle forme
                        del tuo corpo grazie alle manovre profonde e decise che caratterizzano tale trattamento.<br>Inoltre,
                        grazie alla combinazione di manovre di linfodrenaggio, si elimineranno tossine e pesantezza
                        generali.<br>(Adatto a sportivi e a chi vuole rimodellare i propri volumi corporei). </p>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="heading-7">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapse-7" aria-expanded="false" aria-controls="collapse-7">
                <strong>Riflessologia Plantare</strong>
                </button>
            </h2>
            <div id="collapse-7" class="accordion-collapse collapse" aria-labelledby="heading-7"
                data-bs-parent="#accordionTwo">
                <div class="accordion-body">
                    <p>È un trattamento da fare singolarmente oppure in combinazione con altri massaggi. <br>Si tratta
                        di un massaggio riflessologico quindi rivolto al riequilibrio del nostro corpo: attingendo inizialmente
                        sui punti riflessi che si trovano sui piedi, lavoreremo su organi, apparati e sistemi
                        dell'organismo prendendo in considerazione gli aspetti psicosomatici.</p>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="heading-11">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapse-11" aria-expanded="false" aria-controls="collapse-11">
                <strong>Massaggio Distensivo Anti-Stress (completo)</strong>
                </button>
            </h2>
            <div id="collapse-11" class="accordion-collapse collapse" aria-labelledby="heading-11"
                data-bs-parent="#accordionTwo">
                <div class="accordion-body">
                    <p>È un messaggio corpo adatto a tutti, regala buonumore e gioia. <br>Si avvale di manovre distensive ed avvolgenti volte alla riduzione della
                        stanchezza e dello stress psicofisico, dona un senso di abbandono e rilassamento totali.
                    </p>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="heading-8">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapse-8" aria-expanded="false" aria-controls="collapse-8">
                <strong>Cranio Sacrale</strong>
                </button>
            </h2>
            <div id="collapse-8" class="accordion-collapse collapse" aria-labelledby="heading-8"
                data-bs-parent="#accordionTwo">
                <div class="accordion-body">
                    <p>È una tecnica olistica che stimola la capacità di autoguarigione della persona: si avvale di
                        dolci manovre leggere e delicate sulle ossa del cranio, sulla colonna vertebrale e su altre
                        parti del corpo, intervenendo così su un sistema (cranio-sacrale) che collega ogni parte
                        dell'organismo. </p>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="heading-9">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapse-9" aria-expanded="false" aria-controls="collapse-9">
                <strong>Thailandese Zen</strong>
                </button>
            </h2>
            <div id="collapse-9" class="accordion-collapse collapse" aria-labelledby="heading-9"
                data-bs-parent="#accordionTwo">
                <div class="accordion-body">
                    <p>
                        Richiama la tradizione orientale, si effettua per terra su un futon molto comodo e confortevole.
                        <br>Si tratta di un massaggio di "stretching passivo" con manovre di pressione, allungamento e stiramento.
                        <br>Dona elasticità, leggerezza e connessione spirituale.
                    </p>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="heading-10">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapse-10" aria-expanded="false" aria-controls="collapse-10">
                <strong>Aroma-Massaggio</strong>
                </button>
            </h2>
            <div id="collapse-10" class="accordion-collapse collapse" aria-labelledby="heading-10"
                data-bs-parent="#accordionTwo">
                <div class="accordion-body">
                    <p>Durante questo incontro con te stesso/a, le
                        manovre avvolgenti e lineari ti accompagneranno in un momento di riequilibrio energetico.<br>
                        Questo massaggio, grazie alle proprietà terapeutiche degli oli essenziali sui punti energetici del corpo, ti permette di vivere un'esperienza straordinaria: un viaggio olfattivo,
                        sensoriale ed emozionale.
                        </p>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="heading-12">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapse-12" aria-expanded="false" aria-controls="collapse-12">
                <strong>Massaggio con i Fiori di Bach</strong>
                </button>
            </h2>
            <div id="collapse-12" class="accordion-collapse collapse" aria-labelledby="heading-12"
                data-bs-parent="#accordionTwo">
                <div class="accordion-body">
                    <p>Questo massaggio unisce manualità sicure e specifiche all'effetto riequilibrante dei rimedi floreali,
                        prende in considerazione i tuoi stati emotivi armonizzandone gli squilibri.<br>
                        Ti sentirai profondamente rilassato, connesso con la tua vera essenza e maggiormente consapevole delle tue emotività.</p>
                </div>
            </div>
        </div>
    </div>`,
                },
                {
                    id: 2,
                    title: "Floriterapia di Bach",
                    content: `<p>La <strong>Floriterapia di Bach</strong> è un metodo di cura naturale e dolce, che ti permette di <strong>attenuare emozioni e stati d'animo negativi</strong>, così come, a lungo termine, di <strong>sviluppare la tua personalità</strong>.<br><br>

Le essenze floreali scoperte dal <strong>Dottor Edward Bach</strong> mirano a ripristinare la corretta comunicazione tra l'<strong>Io Superiore</strong> e l'<strong>Io fisico</strong>. 
Sbloccando l'energia restituiscono benessere ed equilibrio interiore, portando a una nuova consapevolezza. <br>

I <strong>Fiori di Bach</strong> mirano quindi a far raggiungere il benessere inteso come armonia tra mente, corpo e spirito. <br>

Questa terapia non sostituisce le terapie mediche ma può essere affiancata ad altri metodi terapeutici per aumentare l'efficacia. <br><br>

Per iniziare il tuo viaggio con i fiori di Bach avrai bisogno del rituale di consulenza iniziale: scopriremo insieme il rimedio floreale più adatto a te attraverso pratiche olistiche e sensoriali.  <br>

Alla fine della consulenza riceverai il tuo rimedio floreale e in omaggio un libretto esplicativo che userai come diario e guida personale.</p>`,
                },
                {
                    id: 3,
                    title: "Rituali Sensoriali",
                    content: `<p>I <strong>Rituali Sensoriali</strong> sono trattamenti olistici che ho ideato per portare la persona all'ascolto interiore attraverso dolci pratiche olistiche: dialogo, meditazione, scelta degli oli essenziali, rituale del "lasciar andare" e massaggio. <br>
                                <strong>Ogni rituale è un'esperienza unica.</strong></p>
                                
                                <h5 class="fw-bold mb-0 text-green">Rituale Schiena</h5>
                                <p>Massaggerò la tua schiena e insieme al nostro respiro ti accompagneró nel tuo mondo interiore. Saremo avvolti dall'energia degli oli essenziali e da un'atmosfera che coinvolgerà anima, mente e corpo. 
                                Eliminerai stress mentale e tensione muscolare ritrovando benessere e gioia.</p>

                                <h5 class="fw-bold mb-0 text-green">Rituale d'Armonia</h5>
                            <p>Massaggerò tutto il tuo corpo per armonizzare le tue energie fisiche ed emozionali. Grazie alle manovre profonde ed avvolgenti potrai sentire te stesso in un viaggio sensoriale unico. Sarà un momento di puro benessere in cui ti sentirai in armonia con te stesso e con l'ambiente che ti circonda. Ritroverai buonumore e benessere generale.</p>

                                <h5 class="fw-bold mb-0 text-green">Rituale Sensoriale</h5>
                            <p>La profondità di questo Rituale ci farà perdere la cognizione del tempo e dello spazio poiché l'unica cosa che conterà sarà Lasciarsi Andare. 
                                Massaggerò la tua schiena, il viso e i piedi con l'auto di panni caldi e profumi straordinari. Tutti i tuoi sensi saranno coinvolti per vivere un'esperienza unica. Lascerai stanchezza, stress, tensioni fisiche e mentali per ritrovare un benessere totale.</p>`,
                },
                {
                    id: 4,
                    title: "Corsi di Formazione",
                    content: `<p>I corsi che organizzo mirano a formare nuove figure professionali nell'ambito del benessere olistico.<br><br>

Ogni anno saranno presenti novità in base al mio desiderio, alle mie idee e al mio continuo aggiornamento, ma anche in relazione alla richiesta del mercato, tutto questo per offrire ai miei allievi il meglio in termini di professionalità, contenuti ed esperienze. <br>

I corsi riguardano Tecniche di massaggio e percorsi di Operatore Olistico. </p>

<div class="row m-0">
    <div class="col-12 col-md-6">
        <h5>Corsi di Massaggio Corpo:</h5>
        <ul>
            <li>Massaggio Distensivo</li>
            <li>Massaggio Linfodrenaggio</li>
            <li>Massaggio Linfodrenaggio in gravidanza</li>
            <li>Massaggio di Riequilibrio corporeo</li>
            <li>Massaggio Connettivale</li>
            <li>Massaggio Muscolo-fasciale</li>
            <li>Massaggio Muscolo-linfatico</li>
            <li>Massaggio Articolare</li>
            <li>Riflessologia plantare</li>
            <li>Cranio sacrale</li>
            <li>Thailandese Zen</li>
            <li>Massaggio Bio-emozionale</li>
            <li>Massaggio con Oli essenziali</li>
            <li>Massaggio con Fiori di Bach</li>
        </ul>
    </div>
    <div class="col-12 col-md-6">
        <h5>Corsi di Massaggio Viso:</h5>
<ul>
    <li>Face Therapy</li>
    <li>Biolifting Viso</li>
</ul> 
<h5>Altre discipline:</h5>
<ul>
    <li>Corso di Floriterapia di Bach </li>
    <li>Corso di Aromaterapia</li>
    <li>Corso di preparazione di oleoliti</li>
</ul> 
<p>E tanto altro...</p>
    </div>
</div>`,
                },
                {
                    id: 5,
                    title: "Collaborazioni",
                    content: `<p>Da anni ho imparato che collaborare con altre figure professionali mi porta a conoscere di più me stessa sia come persona che come professionista. In eventi olistici e culturali in cui la parola d'ordine è "Condivisione" arricchisco non solo ogni singolo partecipante ma anche me stessa.<br><br> 

Alcuni degli eventi con collaborazioni passate o in programma sono: </p>
<ul>
    <li>Giornata esperienziale di meditazione, biodanza e altre pratiche olistiche volte all'ascolto interiore;</li>
    <li>Escursione nella natura e yoga in masseria;</li>
    <li>Creazione di oleoliti;</li>
    <li>Raccolta di piante officinali per i trattamenti del benessere;</li>
    <li>Percorsi o giornate esperieziali su chakra, oli essenziali, fiori di Bach, massaggio, ecc...</li>
    <li>Eventi di informazione, convegni, benessere, ecc...</li>
</ul>
<p>
Se vuoi collaborare con me o hai una qualunque idea da propormi, <strong>contattami</strong>.</p>`,
                },
                {
                    id: 6,
                    title: "Crescita Personale",
                    content: `<p>Quando parlo di crescita personale mi riferisco a quel percorso di evoluzione che porta la persona a considerare se stessa come un <strong>essere unico e speciale</strong> che però è stato influenzato da fattori esterni come la cultura, l'educazione, la società, la propria storia familiare, ecc...<br><br>
                        Quando decidi di intrapendere un percorso di <strong>crescita personale</strong> insieme a me, ti stai dando la possibilità di <strong>ritrovare la tua autenticità</strong>.<br>
                        La <strong>realizzazione professionale</strong>, <strong>personale</strong> o anche di piccoli <strong>desideri</strong> dipende da quanto ti conosci, da quanto lavori su di te e da quanto dai ascolto a <strong>ciò che hai dentro</strong>.<br><br>
                        Attraverso la combinazione delle mie discipline, ti aiuterò in questo viaggio interiore volto a riconoscere ed affrontare quei blocchi che impediscono la tua realizzazione.</p>`,
                },

            ],

        }
    },
    methods: {
        showContent(id) {
            const content = document.getElementById('content' + id);
            content.classList.remove("closing");

            const modal = document.getElementById('modal' + id);
            modal.classList.remove("d-none");
        },
        hideContent(id) {
            const content = document.getElementById('content' + id);
            content.classList.add("closing");

            setTimeout(function () {
                const modal = document.getElementById('modal' + id);
                modal.classList.add("d-none");
            }, 300)
        }
    }
}
</script>

<style lang="scss" scoped>
.my-modal {
    position: fixed;
    width: 100vw;
    height: calc(100vh - 72px);
    bottom: 0;
    background: rgba(var(--dark-green-rgb), 0.5);
    z-index: 11;

    .container {
        .content {
            border-radius: 1rem;
            max-height: calc((100vh - 72px) - 3rem);
            animation: 500ms ease appear;
        }

        .closing {
            animation: 500ms ease disappear;
        }
    }
}

@keyframes appear {
    from {
        opacity: 0;
        transform: scale(1%);
    }

    to {
        opacity: 1;
        transform: scale(100%);
    }
}

@keyframes disappear {
    from {
        opacity: 1;
        transform: scale(100%);
    }

    to {
        opacity: 0;
        transform: scale(1%);
    }
}
</style>