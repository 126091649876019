<template>
    <div class="bg-light-champagne pt-4 pb-5">
        <div class="container pt-5 pb-3">
            <div class="row">
                <div class="col-12 col-lg-6 pt-4">
                    <h3 class="gallery text-green">Trascorri del tempo con te stesso</h3>
                    <h1 class="gallery text-green">Vivi la tua esperienza</h1>
                    <div class="py-5 w-75 d-none d-lg-block mt-5">
                        <h4 class="gallery text-start text-green pt-5">Ciao, sono Alessandra</h4>
                        <h5 class="pb-4 text-green">{{innerText}}</h5>
                        <a :href="btnUrl" id="buttn" class="fs-5">{{btnText}}</a>
                    </div>
                </div>
                <div class="col-12 col-lg-6 p-5 d-none d-lg-flex align-items-center">
                    <img src="../../../public/img/blobs/hero.png" alt="Alessandra Guerriero Operatrice Olistica"
                        class="w-100 big-shadow py-4">
                </div>
            </div>
        </div>
        <div class="d-lg-none">
            <img src="../../../public/img/blobs/hero.jpg" alt="Alessandra Guerriero Operatrice Olistica"
                class="d-lg-none w-100 pb-3">
            <div class="container pb-5">
                <div class="py-4 d-lg-none">
                    <h3 class="gallery text-start">Ciao, sono Alessandra</h3>
                    <h5 class="pb-4">{{innerText}}</h5>
                    <a :href="btnUrl" id="buttn" class="fs-5">{{btnText}}</a>
                </div>
            </div>
        </div>
        <div class="py-3 d-none d-lg-block">
            <div class="py-4 d-xxl-none"></div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            innerText: "Sono qui per mostrarti i mezzi possibili e le pratiche olistiche che più possono adattarsi alle tue esigenze: ti guiderò verso te stesso/a in modo semplice e naturale per ritrovare il benessere psicofisico ed emozionale che desideri.",
            btnUrl: "#chi-sono",
            btnText: "Chi sono"
        }
    }
}
</script>

<style lang="scss" scoped>

</style>