<template>
  <div id="app">
    <div id="newOpening">
      <div class="opening-card position-relative text-center py-3 px-4">
        <div class="position-absolute top-0 end-0" @click="hideModal()">
          <i class="fa-solid fa-xmark fs-4 pointer text-green pe-3 pt-2" id="closeOpeningModal"></i>
        </div>
        <h3>Benvenuto nel mio sito!</h3>
        <h5 class="lh-lg">Ti aspetto nel mio<br><span class="text-uppercase fw-bold h4">Studio di Massaggi e Discipline
            Olistiche</span><br> in <a href="https://goo.gl/maps/b4jJ9vwJJ6UK94ZXA" target="_blank" class="text-green">Via
            Bizzarri, 171, Manduria (TA) <i class="fa-solid fa-location-arrow align-middle"></i></a></h5>
      </div>
    </div>
    <TheHeader />
    <TheMain />
    <TheFooter />
  </div>
</template>

<script>
import TheHeader from "./components/TheHeader.vue";
import TheFooter from "./components/TheFooter.vue";
import TheMain from "./components/TheMain.vue";
export default {
  components: { TheHeader, TheFooter, TheMain },
  methods: {
    hideModal() {
      const openingModal = document.getElementById("newOpening");
      openingModal.classList.add("d-none");
    }
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: 'Gallery';
  src: url('../src/assets/fonts/GalleryModern.otf') format('opentype');
}

@font-face {
  font-family: 'Belleza';
  src: url('../src/assets/fonts/Belleza-Regular.ttf') format('truetype');
}


:root {
  --primary-green: #7B8C73;
  --primary-green-rgb: 123, 140, 115;
  --dark-green: #48361E;
  --dark-green-rgb: 72, 54, 30;
  --light-champagne: #FCF8F3;
  --light-champagne-rgb: 252, 248, 243;
  --champagne: #F4E0C8;
  --champagne-rgb: 244, 224, 200;
  --bs-border-color: var(--champagne) !important;
}

#app {
  font-family: 'Belleza';
  color: var(--dark-green);
}

// links and buttons

a {
  text-decoration: none !important;
  color: inherit !important;
  padding: 0 !important;
  margin: 0 !important;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none !important;
    color: inherit !important;
  }
}

button:hover {
  background: var(--primary-green) !important;
  color: white !important;
  box-shadow: 0px 5px 5px rgba(var(--primary-green-rgb), 0.5) !important;
}

#buttn {
  background: none !important;
  outline: none !important;
  border: 1px solid var(--primary-green) !important;
  border-radius: 50px !important;
  padding: 0.5rem 2.5rem !important;
  color: var(--dark-green) !important;
  transition: 300ms ease !important;

  &:hover {
    background: var(--primary-green) !important;
    color: white !important;
    box-shadow: 0px 5px 5px rgba(var(--primary-green-rgb), 0.5) !important;
  }

  &:active {
    border-color: var(--champagne) !important;
    background: var(--champagne) !important;
    color: white !important;
    box-shadow: none !important;
  }

  &.large {
    padding: 0.5rem 3rem !important;
  }
}

// backgrounds

.bg-green {
  background-color: var(--primary-green);
}

.bg-dark-green {
  background-color: var(--dark-green);
}

.bg-light-champagne {
  background-color: var(--light-champagne);
}

.bg-champagne {
  background-color: var(--champagne);
}

// text styles

.belleza {
  font-family: 'Belleza', sans-serif !important;
}

.gallery {
  font-family: 'Gallery', serif !important;
}

.text-green {
  color: var(--primary-green) !important;
}

.text-dark-green {
  color: var(--dark-green) !important;
}

.text-light-champagne {
  color: var(--light-champagne) !important;
}

.text-champagne {
  color: var(--champagne) !important;
}

// headings
h1 {
  font-family: 'Gallery', serif;
  font-size: calc(1.1rem + 4.4vw);
}

h2 {
  font-family: 'Gallery', serif;
  font-size: calc(1rem + 4.6vw);
}

h3 {
  font-size: 20px;
}

h4 {
  font-size: 2rem;
}

@media screen and (min-width: 393px) {
  h1 {
    font-size: calc(1.5rem + 4.4vw);
  }

  h2 {
    font-size: 2.4rem;
  }

  h3 {
    font-size: calc(1.2rem + 1.5vw);
  }

  h4 {
    font-size: 2rem;
  }
}

@media screen and (min-width: 992px) {
  h1 {
    font-size: 3.3rem;
  }

  h2 {
    font-size: 3rem;
  }

  h3 {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1200px) {
  h1 {
    font-size: 4rem;
  }

  h2 {
    font-size: 3.5rem;
  }

  h3 {
    font-size: 2.4rem;
  }

  h4 {
    font-size: 2rem;
  }
}

//shadows & borders

.big-shadow {
  filter: drop-shadow(0px 25px 50px rgba(133, 148, 125, 0.75));
}

.small-shadow {
  filter: drop-shadow(0px 25px 50px rgba(133, 148, 125, 0.25));
}

.border-green {
  border-color: var(--primary-green) !important;
}

.border-dark-green {
  border-color: var(--dark-green) !important;
}

.border-light-champagne {
  border-color: var(--light-champagne) !important;
}

.border-champagne {
  border-color: var(--champagne) !important;
}

.accordion {
  --bs-accordion-btn-focus-border-color: var(--light-champagne) !important;
  --bs-accordion-active-color: var(--champagne) !important;
  --bs-accordion-active-bg: var(--light-champagne) !important;
}

.accordion-button:not(.collapsed) {
  color: var(--dark-green) !important;
  background-color: var(--champagne) !important;
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.accordion-button:focus {
  box-shadow: 0 0 0 0.25rem var(--light-champagne);
}

.accordion-header {
  font-family: 'Belleza', sans-serif;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='black'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
  ;
}

//cursor
.pointer {
  cursor: pointer;
}

//scrollbar
/* width */
::-webkit-scrollbar {
  width: 16px;
  background: #fff;
  border-radius: 1rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary-green);
  border-radius: 16px;
  border: 4px solid #fff;
}

::-webkit-scrollbar-button {
  display: none;
}

/* New Opening Modal */
#newOpening {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  background: rgba(var(--dark-green-rgb), 0.25);
  z-index: 50;

  .opening-card {
    background: linear-gradient(rgba(var(--champagne-rgb), 0.2), rgba(var(--champagne-rgb), 0.2)), url(../public/img/blobs/rosone.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: max-content;
    width: 100%;

    h3 {
      color: var(--primary-green);
    }

    h5 a {
      transition: 200ms;
      color: var(--primary-green);

      &:hover {
        text-shadow: 0 5px 2px var(--champagne);
      }
    }

  }
}</style>