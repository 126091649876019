<template>
    <div class="tr-ease py-5 overflow-hidden">
        <div class="container">
            <h3 class="text-green gallery">Dicono di me</h3>
        </div>
        <div class="p-5 review-container">
            <div class="container">
                <div class="pt-4 d-flex justify-content-center" id="review">
                    <img src="../../../public/img/blobs/quotes.svg" alt="" class="align-self-start pe-5">
                    <div class="p d-flex align-items-center">
                        <p class="text-white py-3 me-5 mt-3" v-html="reviews[selected]"></p>
                    </div>
                    <div></div>
                    <img src="../../../public/img/blobs/quotes.svg" alt="" class="pe-5 closing-quotes">
                </div>
                <div class="d-flex justify-content-center text-champagne gap-5 pe-5 me-5">
                    <i class="fa-solid fa-angle-left pointer fs-1" @click="previousReview()"></i>
                    <i class="fa-solid fa-angle-right pointer fs-1" @click="nextReview()"></i>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            selected: 0,
            reviews: [
                `Grazie per avermi dato l'opportunità di fare un percorso con te. Oltre ad
                essere una massaggiatrice olistica in gamba, sei una persona rara, attenta ai dettagli [...].
                Nonostante la tua giovane età hai un bagaglio di esperienze ricco che volentieri condividi
                con chi ti sceglie per crescere. [...]. Oltre che ad apprendere le tue tecniche mi sento accolta
                ed ascoltata con una dedizione solita di chi ha un animo nobile come il tuo. [...].<br>

                    <span class="fw-semibold text-champagne">Dory</span>`,

                `L'amore per il tuo lavoro e per le persone fanno di te una curatrice perfetta, per il corpo e per l'anima.<br>
                I percorsi intrapresi con te mi hanno cambiato la vita, mi hanno permesso di ascoltare e comprendere il mio corpo;
                la tua guida nel viaggio con i fiori di Bach ha fatto sì che risolvessi problemi fisici ed emozionali che facevano 
                parte di me da sempre e che non credevo possibili da risolvere.<br>Infinitamente grazie.<br>
                
                <span class="fw-semibold text-champagne">Arturo</span>`,

                `La passione e l'immensa dedizione per il tuo lavoro sprizzano da ogni poro, da
                ogni parola, tocco e carezza. [...]<br>
                Grazie per la cura delicata e attenta che hai del corpo e dell'anima.<br>

                <span class="fw-semibold text-champagne">Stefania</span>`,

                `In questi mesi ho avuto modo di intraprendere grazie a te, una vera
                Rinascita…[...]<br>
                Che dire Alessandra, sei una rarità preziosa: sei professionale, presente, sei accoglienza
                pura, [...].<br> Grazie di tutto.
                <br>

                <span class="fw-semibold text-champagne">Giusy</span>`
            ]
        }
    },
    methods: {
        nextReview() {
            const review = document.getElementById('review');
            review.classList.add("opacity-0")

            const th = this;

            setTimeout(function () {
                if (th.selected < (th.reviews.length - 1)) {
                    th.selected++;
                } else {
                    th.selected = 0;
                }
            }, 500)

            setTimeout(function () { review.classList.remove("opacity-0") }, 500);
        },
        previousReview() {
            const review = document.getElementById('review');
            review.classList.add("opacity-0")

            const th = this;

            setTimeout(function () {
                if (th.selected === 0) {
                    th.selected = (th.reviews.length - 1);
                } else {
                    th.selected--;
                }
            }, 500)

            setTimeout(function () { review.classList.remove("opacity-0") }, 500);
        }
    }

}
</script>

<style lang="scss" scoped>
.tr-ease{
    transition: 300ms ease;
}

.review-container {
    background: url('../../../public/img/blobs/reviews.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 270px;

    #review {
        width: 70%;
        margin: auto;
        position: relative;
        transition: 500ms ease-out;
        opacity: 1;
        padding-top: 2.5rem !important;

        .closing-quotes {
            transform: rotate(180deg);
            position: absolute;
            bottom: 1rem;
            right: 50px;
        }

        .p{
            min-height: 210px;
        }
    }
}

@media screen and (max-width: 1230px) {

    .review-container {
        background-size: 115%;

        #review {
            width: 85%;
            padding-top: 5rem !important;
        }
    }
}

@media screen and (max-width: 991px) {

    .review-container {
        background: var(--primary-green);
        border-radius: 70% 30% 70% 30% / 52% 42% 58% 48%;
        padding: 1rem 0 !important;

        #review {
            width: 100%;
            padding-top: 2rem !important;

            img {
                padding-right: 1rem !important;
            }

            .p{
                min-height: 230px;

                p {
                    margin: 0 !important;
                }
            }

            .closing-quotes {
                right: 30px;
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .review-container {
        border-radius: 65% 35% 65% 35% / 52% 42% 58% 48%;

        #review {

            img{
                padding: 0 !important;
            }
            .p {
                max-width: 70%;
                min-height: 440px;

                p{
                    margin: 0 !important;
                }
            }

            .closing-quotes {
                right: 30px;
            }
        }
    }
}
</style>