<template>
    <div class="card p-4 bg-white small-shadow text-green pointer h-100">
        <div class="image" :style="'background-image: url(' + imgUrl + ');'"></div>
        <h4 class="pt-3">{{title}}</h4>
        <p>{{description}}</p>

    </div>
</template>

<script>
    export default {
        props:{
            imgUrl : String,
            title: String,
            description: String,
        }
    }
</script>

<style lang="scss" scoped>
.card{
    border-radius: 2rem;
    transition: 200ms ease;

    .image{
        aspect-ratio: 6/5;
        background-position: center;
        background-size: cover;
        border-radius: 1.3rem 1.3rem 1.3rem 0;
    }

    &:hover{
        transform: scale(110%);
    }
}
</style>