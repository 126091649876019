<template>
    <footer class="bg-green pt-5 pb-3 text-white text-center text-md-start">
        <div class="container">
            <div class="row pb-5">
                <div class="col-12 col-md-3">
                    <img src="../../public/img/Alessandra Guerriero Operatrice Olistica Logo.svg" alt="" class="logo mb-3">
                </div>
                <div class="col-12 col-md-3">
                    <ul>
                        <li><a href="#chi-sono">Chi sono</a></li>
                        <li><a href="#servizi">Servizi</a></li>
                    </ul>
                </div>
                <div class="col-12 col-md-3">
                    <ul>
                        <li>Contatti:</li>
                        <li><a href="tel:+393488125402"><span>+39 3488125402</span></a></li>
                        <li><a href="https://goo.gl/maps/b4jJ9vwJJ6UK94ZXA"><span>Via Bizzarri, 171, Manduria (TA)</span></a></li>
                        <li><a target="_blank" href="https://www.instagram.com/_alessandraguerriero_/"><i class="fa-brands fa-instagram"></i></a><a target="_blank" href="https://www.facebook.com/alessandra.guerriero.operatrice.olistica/"><i
                                    class="fa-brands fa-facebook"></i></a><a target="_blank" href="https://www.linkedin.com/in/alessandra-guerriero-operatrice-olistica/"><i
                                    class="fa-brands fa-linkedin"></i></a></li>
                    </ul>
                </div>
                <div class="col-12 col-md-3">
                    <ul>
                        <li>Alessandra Guerriero</li>
                        <li><span>P.IVA 03287970739</span></li>
                    </ul>
                </div>
            </div>
            <div class="px-5">
                <div class="border-top border-white text-center mx-5 pt-3">
                    <p class="credits">
                        &copy; Alessandra Guerriero 2022 <br>
                        Web design & development: <br class="d-md-none"><a class="text-decoration-underline" href="https://fonzhousefonzie.com">@fonzhousefonzie</a>
                    </p>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {

}
</script>

<style scoped lang="scss">
.logo {
    width: 50%;
    max-height: 50px;
    max-width: 156px;
}

@media screen and (max-width: 991px) {
    .logo {
        width: 100%;
    }
}

ul {
    list-style: none;
    padding: 0.5rem 1rem;

    li {
        padding-bottom: 0.5rem;
        font-size: 1.2rem;

        span {
            font-size: 1rem;
        }

        a {
            span {
                font-size: 1rem;
            }

            i {
                font-size: 1.5rem;
                vertical-align: bottom;
                padding-right: 1.2rem;
            }

            &:hover {
                text-decoration: underline !important;
            }

            &:last-child {
                i {
                    padding-right: 0;
                }
            }
        }
    }

    &.credits {
            font-size: 1rem;
    }
}
</style>