// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/img/blobs/reviews.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tr-ease[data-v-3dbf6432]{transition:.3s ease}.review-container[data-v-3dbf6432]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:50%;background-repeat:no-repeat;background-size:contain;min-height:270px}.review-container #review[data-v-3dbf6432]{width:70%;margin:auto;position:relative;transition:.5s ease-out;opacity:1;padding-top:2.5rem!important}.review-container #review .closing-quotes[data-v-3dbf6432]{transform:rotate(180deg);position:absolute;bottom:1rem;right:50px}.review-container #review .p[data-v-3dbf6432]{min-height:210px}@media screen and (max-width:1230px){.review-container[data-v-3dbf6432]{background-size:115%}.review-container #review[data-v-3dbf6432]{width:85%;padding-top:5rem!important}}@media screen and (max-width:991px){.review-container[data-v-3dbf6432]{background:var(--primary-green);border-radius:70% 30% 70% 30%/52% 42% 58% 48%;padding:1rem 0!important}.review-container #review[data-v-3dbf6432]{width:100%;padding-top:2rem!important}.review-container #review img[data-v-3dbf6432]{padding-right:1rem!important}.review-container #review .p[data-v-3dbf6432]{min-height:230px}.review-container #review .p p[data-v-3dbf6432]{margin:0!important}.review-container #review .closing-quotes[data-v-3dbf6432]{right:30px}}@media screen and (max-width:576px){.review-container[data-v-3dbf6432]{border-radius:65% 35% 65% 35%/52% 42% 58% 48%}.review-container #review img[data-v-3dbf6432]{padding:0!important}.review-container #review .p[data-v-3dbf6432]{max-width:70%;min-height:440px}.review-container #review .p p[data-v-3dbf6432]{margin:0!important}.review-container #review .closing-quotes[data-v-3dbf6432]{right:30px}}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
