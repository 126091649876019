<template>
    <div class="text-green" id="chi-sono">
        <div class="d-lg-none">
            <img src="../../../public/img/blobs/bio.jpg" alt="Alessandra Guerriero Operatrice Olistica Massaggio Natura"
                class="w-100 mobile-image">
        </div>
        <div class="container">
            <div class="py-4 d-none d-lg-block"></div>
            <div class="row py-5">
                <div class="col-12 col-lg-6 pb-3 px-3">
                    <h3 class="pb-4 gallery">Ciao, sono Alessandra Guerriero</h3>
                    <p class="pb-4">Amo la natura da sempre: sono affascinata dai suoi messaggi chiari, delicati e forti allo stesso tempo.
                        <br>Questo tipo di attrazione mi ha permesso di scoprire che noi siamo natura e averlo compreso mi ha avvicinata al <strong>contatto</strong> in tutte le sue forme.
                        <br><br>
                        Il contatto fisico è stato il mio primo approccio sin dalla nascita: con le carezze di mia madre
                        mi sentivo <strong>al sicuro e protetta</strong>. Così, nel tempo, ho sviluppato la capacità di essere d'aiuto agli altri attraverso di esso, in particolare con il massaggio.
                        <br><br>
                        Tutto è iniziato più di 10 anni fa, quando, frequentando la scuola d'estetica, mi
                        resi conto che la reale bellezza derivasse da un benessere più profondo.<br><br>

                        Così, appena conosciuto il massaggio, me ne innamorai alla follia. Un'arte così antica e potente
                        che ancora oggi mi porta a voler alimentare le mie conoscenze ed esperienze.<br><br>

                        La cosa meravigliosa è stata quella di aver scoperto che massaggiare, per me, non era solo
                        accarezzare o dare un beneficio momentaneo.. ma era qualcosa di più grande.<br>
                        Era guidare verso il proprio mondo interiore attraverso un tocco sapiente e soprattutto
                        presente.<br><br>

                        Ma prima volevo e dovevo partire da me stessa, scoprire quindi il mio mondo interiore.<br><br>

                        Così ho iniziato a sperimentarmi e a ri-trovarmi attraverso lo studio e l'approfondimento di
                        altre discipline olistiche per usarle come ulteriore mezzo di supporto lavorativo e personale.<br><br>

                        Ho incontrato la pratica della <strong>Meditazione</strong> per
                        conoscere i meccanismi della mente e dell'anima;<br>
                        l'<strong>Aromaterapia</strong> per avvicinarmi al linguaggio della natura e scoprirne i segreti che risiedono in
                        noi;<br>
                        la <strong>Floriterapia</strong> di Bach come supporto emotivo e pratico nel percorso della vita;<br>
                        lo studio della <strong>Psicosomatica</strong> per poter leggere i messaggi del nostro corpo e le loro verità;<br>
                        e tanto tanto altro…<br><br>

                        In contemporanea ho sempre formato e guidato altre figure come estetiste, massaggiatori e
                        operatori del benessere nel loro viaggio personale per permettergli di essere di reale aiuto per
                        gli altri.<br><br>

                        Quindi sono Alessandra, massaggiatrice olistica esperta in Riequilibrio Corporeo,
                        Psicosomatica, Floriterapia di Bach e Aromaterapia con particolare attenzione alle discipline bio-naturali,
                        energetiche e sensoriali.<br><br>

                        O più semplicemente <strong>Operatrice Olistica</strong>.<br><br>

                        Una guida verso la tua personale <strong>Esperienza di Benessere e Consapevolezza</strong>.
                    </p>
                    <a id="buttn" class="large" href="javascript:void(0);" @click="clickContactsButton()">Contattami</a>

                </div>
                <div class="col-12 col-lg-6 d-none d-lg-block text-center pt-5">
                    <img src="../../../public/img/blobs/bio.png" alt="Alessandra Guerriero Operatrice Olistica Massaggio Natura"
                        class="blob big-shadow">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods:{
        clickContactsButton(){
            document.getElementById("contacts-buttn").click();
        }
    }
}
</script>

<style lang="scss" scoped>
.blob {
    max-height: 615px;
}
</style>