<template>
    <main>
        <MainHero/>
        <MainFirstSection/>
        <MainServices/>
        <MainBiography/>
        <MainReviews/>
    </main>
</template>

<script>
import MainBiography from './sections/MainBiography.vue';
import MainFirstSection from './sections/MainFirstSection.vue';
import MainHero from './sections/MainHero.vue';
import MainServices from './sections/MainServices.vue'
import MainReviews from './sections/MainReviews.vue'

    export default {
    components: { MainHero, MainFirstSection, MainServices, MainBiography, MainReviews }
}
</script>

<style lang="scss" scoped>

</style>