<template>
    <div class="text-green">
        <div class="d-lg-none">
            <img src="../../../public/img/blobs/firstSection.jpg"
                alt="Alessandra Guerriero Operatrice Olistica Massaggio Natura" class="w-100 mobile-image">
        </div>
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-6 d-none d-lg-block">
                    <img src="../../../public/img/blobs/firstSection.png"
                        alt="Alessandra Guerriero Operatrice Olistica Massaggio Natura" class="blob big-shadow">
                </div>
                <div class="col-12 col-lg-6 py-5 px-3">
                    <h2 class="pt-5 pb-4">Come posso aiutarti?</h2>
                    <p class="pb-4">Come <strong>operatrice olistica</strong>, <strong>massaggiatrice</strong> e
                        <strong>formatrice</strong> posso guidarti nella scelta
                        del percorso migliore per te: personalizzando ogni singolo incontro potrai vivere la <strong>Tua
                            esperienza</strong> in modo unico.
                        <br>
                        Potrai raggiungere pian piano i tuoi obiettivi di vita sana, bella, ottenendo l'<strong>armonia</strong> con te
                        stesso/a e con ciò che ti circonda. Potrai offrire a te stesso/a la possibilità di vivere una
                        vita
                        più <strong>consapevole</strong>, piena ed appagante.
                    </p>
                    <a id="buttn" class="large" href="#servizi">I miei servizi</a>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.blob {
    max-height: 615px;
    position: relative;
    top: -6rem;
}

.mobile-image {
    margin-bottom: -4.5rem;
}
</style>